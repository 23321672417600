<template>
  <div>
    <div class="Box">
      <div class="tabbar">
        <img @click="goback" src="../../static/common/logo.png" alt="">
      </div>
      <h3>免责声明</h3>
      <div class="line"></div>
      <ul>
        <h4>1、 互联网故障</h4>
        <li>
          由于互联网的运作不在交易商控制范围之内，信号经由互联网和路由传送的稳定性需要依赖客户设备或连接的可靠性，故交易商不对互联网上交易中出现的通讯故障、错误或延迟负责。
        </li>
        <h4>2、 市场风险和网上交易</h4>
        <li>
          伦敦金/银交易涉及相当大的风险，其并非对每个投资者都适合。请参照风险披露声明和本协议，了解有关风险的详细资料。虽然网上交易为客户带来很多方便或使得交易更有效率，但它并不降低伦敦金/银交易本身的风险。
        </li>
        <h4>3、 密码保护</h4>
        <li>
          客户必须将密码保密。客户同意对所有经电邮或电子交易平台、电话、书面传送给交易商的指示负责，这些指示只要符合客户密码或客户签名和账号号码认证，交易商即可善意相信这是客户的意思表示。交易商并没有责任对该意思表示作实质性的查询，也没有责任因为依据该意思表示所采取的或不采取的行动所造成的后果负责。客户须对密码的保密性、安全性及其使用独自承担责任。
        </li>
        <h4>4、 报价错误</h4>
        <li>
          倘若报价或成交价出现错误，交易商对于账号结算的相应错误并不负责。有关错误包括但不限于：交易员的错误报价、非国际市场价之报价、或是任何报价错误(例如由于硬体、软体或者通讯线路或系统故障导致报价错误或者第三者提供了错误的外部资料)。交易商不需为错误所导致的账号损失负责，客户下单时需预留足够的时间以供系统计算所需保证金及执行订单。若订单的执行价格或订单设定价格和市场价格过于接近，这可能会触发其他订单(所有订单类型)发出保证金提示。交易商不会对由于系统没有足够时间执行订单或进行运算所产生的保证金提示、账号结余或账号头寸负责。上文不得视作内容尽列，一旦发生报价或执行错误，交易商保留对有关账号进行必要更正或调整的权利，通过此种绝对的酌情权，交易商可判定客户利用报价错误而成立的订单无效，相关盈亏自始不产生效力。若因此带来任何损失、损害或责任，客户同意予以赔偿使交易商不受损害。
        </li>
        <h4>5、 操控价格、执行及平台</h4>
        <li>
          交易商严禁客户以任何形式对价格、执行及平台进行操控，包括严禁多个主体使用一个账号、单个主体使用多个账号等。此种操控可能导致交易未成立或可被撤销的法律后果。若交易商怀疑任何交易账号有从事操控伦敦金/银合约价格的嫌疑，交易商保留对账号进行调查及审核、并从涉嫌账号中扣除由相关操作所赚取盈利款项的权利。对于涉嫌从事操控的账号，交易商依据绝对酌情权，要求交易员进行干预、对下单进行核准以及终止有关客户的账号。对于由违规性套戥及或操控所产生的任何纠纷，交易商将依据绝对酌情权进行解决。交易商可依据酌情权决定向任何相关监管机构或执法机构报告有关事件。于此处所陈述的任何行动或决议并不影响交易商对客户和其雇员拥有的任何权利。
        </li>
        <h4>6、套戥</h4>
        <li>
          互联网、连线延误、报价上的误差或报价系统的漏洞有时会造成在本公司交易平台上的报价出现错误。因报价错误而透过差价获利的行为，并不能存在于客户直接向本公司平台进行买卖的场外交易市场中。本公司不容许客户在本公司的交易平台上进行此等违规的套戥活动。本公司保留权利对涉及上述交易的账户进行必要的修正和调整。本公司可依据绝对酌情权，要求交易员进行干预或核准所有下单以及终止有关客户的账户，而不需事先通知客户。对违规的“套戥”或操控价格而产生的纠纷，本公司将依据绝对酌情权进行解决。本公司保留冻结客户提款的权利直至能够完全解决上述的问题为止。
        </li>
      </ul>
    </div>
    <contactusVue></contactusVue>
    <HumanService/>
  </div>
</template>

<script>
import ContactusVue from "./Contactus.vue";
import HumanService from './HumanService/index.vue';
export default {
  name:'Disclaimers',
  components:{
    ContactusVue,
    HumanService
  },
  created(){
    this.$emit('controlShow',true);
  },
  methods:{
    goback(){
       window.location.href = window.location.origin
    }
  }
}
</script>

<style lang="less" scoped>
  .Box{
    width: 1138px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 50px 0 110px;
    .tabbar{
      width: 100%;
      img{
        width: 130px;
      }
    }
    h3{
      font-size: 24px;
    }
    .line{
      width: 100%;
      height: 1px;
      background-color: #eaeaea;
      margin: 40px auto 60px;
    }
    ul{
      li{
        line-height: 25px;
        letter-spacing: 1px;
        margin-bottom: 40px;
        color: #6d6d6d;
        span{
          color: #fe0100;
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    .Box{
      width: 100%;
      padding: 20px 15px;
      .tabbar{
        width: 100%;
        img{
          width: 110px;
        }
      }
      h3{
        font-size: 15px;
      }
      .line{
        margin: 10px auto 20px;
      }
      ul{
        li{
          line-height: 25px;
          font-size: 15px;
          text-align: justify;
          span{
            color: #fe0100;
          }
        }
      }
    }
  }
</style>